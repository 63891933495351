<template>
  <v-card class="component"
  style="min-height: 400px;">
      <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Empreendimentos</span>
          <v-spacer></v-spacer>
          <!-- <span class="text-xs text--disabled cursor-pointer">Ver todas</span> -->
        </v-card-title>

        <v-card-text>
          <v-list style="background-color: transparent !important;">
            <v-list-item
              v-for="(data,index) in obr"
              :key="data.id"
              :class="`d-flex px-0 ${index > 0 ? 'mt-4':''}`"
            >
              <v-img
                contain
                max-height="30"
                max-width="30"
                :src="data.img"
                class="me-3"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-auto pe-2">
                  <h4 class="font-weight-semibold">
                    {{ data.title }}
                  </h4>
                  <span class="text-xs">{{ data.subtitle }}</span>
                </div>

                <span class="font-weight-semibold success--text">{{ data.amount }}</span>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>

      <v-divider
        class="my-sm-5 mx-5"
        :vertical="$vuetify.breakpoint.smAndUp"
      ></v-divider>

      <div class="flex-grow-1">
        <v-card-title>
          <span class="me-3">Imóveis</span>
          <v-spacer></v-spacer>
          <span class="text-xs text--disabled cursor-pointer">Visualizar todos</span>
        </v-card-title>

        <v-card-text>
          <v-list style="max-height: 400px; overflow-y: auto; background-color: transparent !important;">
            <v-list-item
              v-for="(data,index) in this.empreendiment"
              :key="data.id"
              :class="`d-flex px-0 ${index > 0 ? 'mt-4':''}`"
            >
              <v-img
                max-height="30"
                max-width="30"
                :src="data.img"
                class="me-3"
              ></v-img>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div class="me-auto pe-2">
                  <h4 class="font-weight-semibold">
                    {{ data.title }}
                  </h4>
                  <span class="text-xs">{{ data.subtitle }}</span>
                </div>

                <span class="font-weight-semibold secondary--text"></span>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </div>
  </v-card>
</template>

<script>



export default {
  props: {
    empreendiment: { type: Array, default: [] },
    obr: { type: Array, default: [] },
  },
  mounted: function(){
  },
  setup() {
  }
}
</script>
