<template>
  <div>
    <button
      aria-label="WhatsApp"
      @click="handleClick"
      :style="fabStyle"
    >
      <v-icon color="white">mdi-whatsapp</v-icon>
    </button>
  </div>
</template>

<script>
import strings from '@/common/strings';

export default {
  name: 'Whatsapp',
  data() {
    return {
      whatsapp: strings.whatsapp, // substitua pela variável de ambiente no Vue
    };
  },
  computed: {
    fabStyle() {
      return {
        position: 'fixed',
        bottom: '20px',
        right: '10px',
        backgroundColor: '#4caf50',
        color: '#FFF',
        border: 'none',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      };
    },
  },
  methods: {
    handleClick() {
      const whatsappUrl = `https://wa.me/${this.whatsapp}`;
      window.open(whatsappUrl, '_blank');
    },
  },
};
</script>

<style scoped>
button {
  outline: none;
}
</style>